<template>
	<v-container fill-height fluid class="pa-0 fac-background-img">
		<v-row no-gutters align="center" justify="center">
			<v-col sm="12" md="6" cols="12" :class="$vuetify.breakpoint.mdAndUp ? 'pa-12' : 'pa-2'">
				<v-alert border="left" colored-border type="error" elevation="2" dismissible :value="error" class="animate__animated animate__flash">
					Email inválido
				</v-alert>

				<!-- EMAIL REQUEST -->
				<v-card v-if="!showForm && !requestSent && !passwordChanged">
					<v-card-title class="primary--text" :class="$vuetify.breakpoint.smAndDown ? 'subtitle-2' : ''">
						Por favor, informe seu email abaixo:
					</v-card-title>
					<v-row no-gutters>
						<v-col sm="12" md="12" cols="12" class="pa-4">
							<v-form @submit.prevent="doForgotPassword" ref="forgotForm">
								<v-row row wrap justify-center>
									<v-col cols="12">
										<v-text-field :disabled="loading" outlined dense hide-details :label="'Email'" :placeholder="'Email'" @keydown.space.prevent v-model.trim="user.email" :prepend-inner-icon="'mdi-email'" :rules="[$rules.required, $rules.email]"></v-text-field>
									</v-col>
								</v-row>

								<v-card flat class="transparent mt-3">
									<v-card-actions class="pa-0">
										<v-spacer></v-spacer>
										<v-btn depressed class="px-5" color="success" type="submit" :loading="loading">{{ 'Solicitar Redefinição' }}</v-btn>
									</v-card-actions>
								</v-card>
							</v-form>
						</v-col>
					</v-row>
				</v-card>

				<!-- RESET PASSWORD -->
				<v-card v-if="showForm && !requestSent && !passwordChanged">
					<v-card-title class="primary--text" :class="$vuetify.breakpoint.smAndDown ? 'subtitle-2' : ''">
						Cadastre uma nova senha:
					</v-card-title>
					<v-row no-gutters>
						<v-col sm="12" md="12" cols="12" class="pa-4">
							<v-form @submit.prevent="doResetPassword" ref="resetForm">
								<v-row row wrap justify-center>
									<v-col cols="12">
										<v-text-field :disabled="loading" outlined dense hide-details :label="'Nova Senha'" :placeholder="'Nova Senha'" :prepend-inner-icon="'mdi-lock'" :append-icon="login.showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="login.showPassword ? 'text' : 'password'" @click:append="login.showPassword = !login.showPassword" v-model="login.password" :rules="[$rules.required]"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field :disabled="loading" outlined dense hide-details :label="'Confirmar Senha'" :placeholder="'Confirmar Senha'" :prepend-inner-icon="'mdi-lock'" :append-icon="login.showPassword2 ? 'mdi-eye' : 'mdi-eye-off'" :type="login.showPassword2 ? 'text' : 'password'" @click:append="login.showPassword2 = !login.showPassword2" v-model="login.password2" :rules="[$rules.required, $rules.match(login.password, login.password2)]"></v-text-field>
									</v-col>
								</v-row>

								<v-card flat class="transparent mt-3">
									<v-card-actions class="pa-0">
										<v-spacer></v-spacer>
										<v-btn depressed class="px-5" color="success" type="submit" :loading="loading">{{ 'Cadastrar' }}</v-btn>
									</v-card-actions>
								</v-card>
							</v-form>
						</v-col>
					</v-row>
				</v-card>

				<!-- REQUEST CONFIRMATION -->
				<v-card v-if="requestSent || passwordChanged">
					<v-card-title class="primary--text" :class="$vuetify.breakpoint.smAndDown ? 'subtitle-2' : ''">
						<span v-show="requestSent">Solicitação enviada com sucesso.</span>
						<span v-show="passwordChanged">Senha alterada com sucesso.</span>
					</v-card-title>
					<v-row no-gutters>
						<v-col sm="12" md="12" cols="12" class="pa-4">
							<v-row row wrap justify-center>
								<v-col cols="12" v-show="requestSent">
									Verifique seu email para acessar o link para a alteração da senha.
								</v-col>
								<v-col cols="12" v-show="passwordChanged">
									Sua senha foi alterada com sucesso. Agora é só realizar o login!
								</v-col>
							</v-row>

							<v-card flat class="transparent mt-3">
								<v-card-actions class="pa-0">
									<v-spacer></v-spacer>
									<v-btn v-if="requestSent" depressed class="px-5" color="primary" @click="$router.push('/entrar')">{{ 'Voltar' }}</v-btn>
									<v-btn v-if="passwordChanged" depressed class="px-5" color="success" @click="$router.push('/entrar')">{{ 'Entrar' }}</v-btn>
								</v-card-actions>
							</v-card>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import "../assets/css/animate.min.css"
import bgImage from '../assets/img/fac-img2.jpg'
import moment from 'moment'

export default {
	name: 'Forgot',
	props: ['token', 'id'],
	data() {
		return {
			loading: false,
			error: false,
			image: bgImage,
			showForm: false,
			requestSent: false,
			passwordChanged: false,
			user: {
				email: ''
			},
			login: {
				password: '',
				password2: '',
				showPassword: false,
				showPassword2: false
			}
		}
	},
	mounted() {
		if (this.token && this.id) {
			this.showForm = true
		}
	},
	methods: {
		doForgotPassword() {
			this.error = false

			// TRIM FIRST
			if (this.user.email) {
				this.user.email = this.user.email.trim()
			}

			// VALIDATE
			this.$nextTick(() => {
				if (this.$refs.forgotForm.validate()) {
					this.loading = true
					this.$store.dispatch('doForgotPassword', { email: this.user.email }).then(() => {
						this.loading = false
						this.requestSent = true
					}).catch(() => {
						this.loading = false
					})
				}
				else {
					this.error = true
				}
			})
		},
		doResetPassword() {
			this.error = false
			if (this.$refs.resetForm.validate()) {
				this.loading = true

				const userData = {
					id: this.id,
					token: this.token,
					password: this.login.password
				}

				this.$store.dispatch('doResetPassword', userData).then(() => {
					this.loading = false
					this.passwordChanged = true
				}).catch(() => {
					this.loading = false
				})
			}
			else {
				this.error = true
			}
		}
	}
}
</script>

<style scoped>
body {
	background-color: grey;
}
.fac-background-img {
	background: linear-gradient(
		to top,
		rgba(80,80,80,.6),
		rgba(20,20,20,.8)
	),url('../assets/img/fac-img2.jpg') center center no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
</style>
